@import '../../../styles/vars-and-mixins/index.less';

.card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  row-gap: 1.8rem;
}

.card {
  background: @header-bg-color;
  border-radius: @rs-primary-border-radius;
  padding: 16px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
}

.password-requirements-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 16px;
}
