@import '../../../../../../styles/vars-and-mixins/index.less';

.thumbnail-image {
  width: 6vw;
  height: 6vw;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    width: 3vw;
    object-fit: contain;
  }
}

.thumbnail-selection {
  a:focus {
    border: 2px solid @rs-primary-color;
  }
}
