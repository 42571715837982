@import '../../../styles/vars-and-mixins/index.less';

.ovr-view-type-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #ffffff;
  border: @rs-primary-border;

  svg {
    width: 16px;
    height: 16px;
  }

  g,
  path {
    fill: @rs-primary-icon-color;
  }

  &.selected {
    background-color: fade(@rs-primary-color, 10%);
    border-radius: 4px;

    g,
    path {
      fill: @rs-primary-icon-active-color;
    }
  }
}
