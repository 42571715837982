.product-item-header-wrapper {
  margin-bottom: 21px;

  .product-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.product-item-content-container {
  display: flex;
  align-items: flex-start;
  max-width: 1600px;
  column-gap: 1rem;
}

.product-item-details,
.edit-content-container {
  width: 50%;
}

.product-item-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: sticky;
  top: 150px;
}

.edit-content-container {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .edit-form-wrapper {
    background: #ffffff;
    border-radius: 4px;
    padding: 1rem;
  }

  .not-editable-info-wrapper {
    background: #ffffff;
    padding: 1rem;
    border-radius: 4px;
    padding-bottom: 3rem;
  }
}
