.forgot-password-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #8e24aa;
}

.forgot-password-card-content-wrapper {
  padding: 18px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .forgot-password-steps {
    margin-top: 16px;
  }

  .forgot-password-step-wrapper {
    margin-top: 28px;
  }

  .forgot-password-requirements {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 6px;
  }

  .forgot-password-steps-header {
    font-weight: 700;
    text-align: center;
  }

  .forgot-password-input-wrapper {
    margin-top: 16px;

    .forgot-password-input-label {
      display: inline-block;
      color: #828282;
      margin-bottom: 8px;
    }
  }
}
