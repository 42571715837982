.brand-form-status {
  display: flex;
  justify-content: space-between;
}
.brand-form-details {
  display: flex;
  justify-content: space-between;
}


.form-item-manufacturers-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .form-item-select {
    margin-bottom: 0;
    .ant-select-selector,
    .ant-input {
      min-width: 12.5rem;
      margin-bottom: 1rem;
    }
  }
}
