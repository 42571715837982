@import '../../../../../styles/vars-and-mixins/index.less';

.filters-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ant-spin-text {
    color: @rs-primary-text-color;
  }
}

.selected-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  .filter-category-title {
    width: 100%;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  .selected-filter-item {
    border-radius: 0.25rem;
    background-color: #dee2e6;
    color: #333;
    padding: 0.25rem 0.5rem;
  }
}

.panel-filters-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;

  .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }
}
