@import '../../../../..//styles/vars-and-mixins/index.less';

.cell-groups-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px solid hsl(0, 0%, 93%);
  border-radius: @rs-primary-border-radius;
  padding: 1rem;

  .add-cell-group-btn {
    width: 100%;
  }

  .mt {
    margin-top: 2rem;
  }
}

.cell-groups-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 1rem;
  gap: 1rem;
}
