@import '../../../../../styles/vars-and-mixins/index.less';

.builder-layout-toolbar-wrapper {
  position: fixed;
  top: 144px;
  left: 280px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  &.collapsed-sidebar {
    left: 110px;
  }

  .toolbar-drag {
    display: none;
    justify-content: flex-end;
    cursor: move;
    position: absolute;
    right: -12px;
    top: -6px;
    height: 16px;
    width: 24px;
  }

  &:hover {
    .toolbar-drag {
      display: flex;
    }
  }
}

.builder-layout-toolbar {
  display: flex;
  flex-direction: row;
}

.toolbar-actions-group {
  display: flex;
  flex-direction: row;
  margin-right: 1.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  background-color: white;
  border-radius: 4px;
  height: 44px;
  max-height: 44px;
  min-width: 34px;
  justify-content: center;

  &.no-margin {
    margin-right: 0;
  }

  &.hover-effect:hover {
    background: @rs-primary-color;
  }
}

.measuring-tools-menu {
  & .ant-dropdown-menu-item {
    padding: 8px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .ant-dropdown-menu-item:hover {
    background: @rs-primary-color !important;

    & svg rect {
      fill: white;
    }

    & svg circle {
      fill: white;
    }
  }
}

.toolbar-action-icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-right: 0.5px solid #dee2e6;
  border-left: 0.5px solid #dee2e6;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &:first-child {
    border-radius: 4px 0 0 4px;
    border-left: none;
  }

  &:last-child {
    border-right: none;
    border-radius: 0 4px 4px 0;
  }

  &:only-of-type {
    border: none;
    border-radius: 4px;
  }
}
