@import '../../../styles/vars-and-mixins/index.less';

.button {
  &.success {
    color: white;
    background: @rs-success-color;
    border-color: @rs-success-color;

    &:hover {
      background: @rs-success-color-light;
      border-color: @rs-success-color-light;
    }

    &:active {
      background: @rs-success-color-dark;
      border-color: @rs-success-color-dark;
    }
  }

  &.warning {
    color: white;
    background: @rs-warning-color;
    border-color: @rs-warning-color;

    &:hover {
      background: @rs-warning-color-light;
      border-color: @rs-warning-color-light;
    }

    &:active {
      background: @rs-warning-color-dark;
      border-color: @rs-warning-color-dark;
    }
  }
}
