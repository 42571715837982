@import '../../../../../styles/vars-and-mixins/index.less';

@side-panel-header-height: 54px;

.side-panel-icon {
  .rs-icon();
}

.properties-wrapper {
  height: calc(100% - @side-panel-header-height);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.builder-side-panel {
  background: #ffffff;
  position: fixed;
  width: 400px;
  top: calc(
    68px + 52px
  ); //ant-layout-header height + store-builder-header height .. make these vars later
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.3s;
  border-left: 1px solid #dee2e6;
}

.side-panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-top: @rs-primary-border;
  border-bottom: @rs-primary-border;
  height: @side-panel-header-height;
  padding: 0 24px;

  &.no-border-bottom {
    border-bottom: unset;
  }

  &.no-border-top {
    border-top: unset;
  }
}

.fixtures-side-panel-section {
  height: 50%;
  max-height: 50%;
  transition: all 0.3s;
  overflow: hidden;

  &.hide {
    height: unset;
  }

  &.full-height {
    height: calc(100% - @side-panel-header-height);
    max-height: calc(100% - @side-panel-header-height);
  }

  .fixtures-wrapper {
    overflow-y: auto;
    height: calc(100% - @side-panel-header-height);
    display: none;

    &.visible {
      display: block;
    }
  }
}

.properties-side-panel-section {
  height: 50%;
  max-height: 50%;
  transition: all 0.3s;

  &.hide {
    height: unset;
  }

  &.full-height {
    height: calc(100% - @side-panel-header-height);
    max-height: calc(100% - @side-panel-header-height);
  }
}

.fixture-items-wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 12px;
  column-gap: 8px;

  &.two-column {
    grid-template-columns: auto auto;
  }

  &.one-column {
    grid-template-columns: auto;
  }

  &.disable-drag-n-drop {
    opacity: 0.4;
    pointer-events: none;
  }
}
