.shoppers-queue-line-wrapper {
  .queue-line-header {
    height: 54px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding: 0 24px;
    color: white;
    justify-content: space-between;
  }

  .queue-line-shopper-item {
    height: 50px;
    display: flex;
    align-items: center;
    border-style: solid;
    border-width: 1.5px;
    border-top: none;
    box-sizing: content-box;
  }

  .shopper-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .shopper-order-in-line-wrapper {
    min-width: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .shopper-order-in-line {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 3px;
    font-size: 16px;
    font-weight: 700;
  }

  .shopper-actions {
    background: #f2f2f2;
    width: 100%;
    padding-left: 16px;
  }

  .shopper-dropdown-action-label {
    padding: 0 20px;
    color: #4f4f4f;
    font-size: 14px;
    font-weight: 600;
  }

  .shopper-type-image-wrapper {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .remove-shopper-icon-wrapper {
    min-width: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
    height: 50px;
    cursor: pointer;
  }

  .queue-line-tool-info {
    height: 18px;
    width: 18px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .alert-overlapping-shopper {
    color: white;
    background: #eb5757;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -6px;
    font-weight: 600;
    right: 6px;
    z-index: 1;
  }
}

.queue-line-shoppers-list-wrapper {
  overflow-y: auto;
}

.queue-line-instructions-tooltip-card {
  min-width: 330px;

  & .ant-tooltip-inner {
    border-radius: 10px;
    border: 1px solid;
    background: white;
  }
}
