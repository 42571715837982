@import '../../../styles/vars-and-mixins/index.less';

.status-view-wrapper {
  border-radius: @rs-item-border-radius;
  min-height: 300px;

  .status-card {
    background: #ffffff;
    padding: 26px 33px;
    border-radius: @rs-item-border-radius;

    .status-value {
      font-weight: 700;
      font-size: 60px;
    }

    .total-products {
      color: @rs-primary-color;
    }

    .needs-processing {
      color: @rs-warning-color;
    }

    .status-detail {
      padding: 12px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: @rs-primary-border;

      &:last-child {
        border: none;
      }
    }
  }

  .category-section {
    margin-top: 16px;
    background: #ffffff;
    padding: 26px 33px;
    border-radius: @rs-item-border-radius;
  }
}
