.checker-wrapper {
  margin: 1.5rem 2.625rem;

  .form-header {
    font-size: 1.125rem;
    font-weight: 700;
    color: #323a46;
    margin: 0;
  }

  .form-desc {
    color: #272e37;
    margin: 0;
    margin-bottom: 1.25rem;
  }

  .uploader-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .uploader-header {
      font-size: 1.5rem;
      font-weight: 700;
      color: #323a46;
      margin-top: 0.5rem;
    }

    .uploader-info {
      font-size: 0.875rem;
      color: #9ba4aa;
    }
  }

  .pog-result-header {
    width: 100%;

    .pog-search-bar {
      display: flex;
      justify-content: space-between;

      .pog-search-input {
        width: 30%;
        margin-bottom: 1.5rem;
      }
    }
  }
}
