@import '../../../styles/vars-and-mixins/index.less';

.product-library {
  &__search-bar-container {
    width: 100%;
  }

  &__content {
    display: flex;
    column-gap: 14px;

    &-sidebar {
      width: 274px;
    }

    &-main {
      display: flex;
      flex-direction: column;
      row-gap: 14px;
      width: calc(100% - 288px);
    }
  }

  &__items-list-container {
    display: flex;
    height: 100%;
  }

  &__filter-menu {
    min-width: 274px;
    border-radius: @rs-item-border-radius;
    margin-bottom: 14px;
    height: 100%;
  }

  &__search-bar {
    display: flex;
    justify-content: space-between;
    border-radius: @rs-item-border-radius;
    margin-bottom: 14px;
    background-color: white;
    padding: 14px;
    column-gap: 15rem;
    row-gap: 14px;
    width: 100%;
    flex-wrap: wrap;

    &-left {
      display: flex;
      flex: 1;
    }

    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__search-input {
    min-width: 200px;
    width: 100%;
    margin-left: 2rem;

    .ant-input-affix-wrapper {
      &:not(.ant-input-affix-wrapper-disabled):hover,
      &:focus,
      &-focused {
        border-color: @rs-primary-color !important;
      }
    }

    ::selection {
      background: @rs-primary-color !important;
    }
  }

  &__dropdown-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 260px;
    flex-grow: 1;
  }

  &__view-type-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 4px;
    background-color: #ffffff;
    border: @rs-primary-border;

    svg {
      width: 16px;
      height: 16px;
    }

    g,
    path {
      fill: @rs-primary-icon-color;
    }

    &--selected {
      background-color: fade(@rs-primary-color, 10%);
      border-radius: 4px;

      g,
      path {
        fill: @rs-primary-icon-active-color;
      }
    }
  }
}

.ant-input:focus {
  border-color: @rs-primary-color !important;
  outline: 0;
  box-shadow: 0 0 0 2px fade(@rs-primary-color, 20%);
}
