@import '../../../../../styles/vars-and-mixins/index.less';

.cell-group-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  border: 1px solid hsl(0, 0%, 93%);
  border-radius: @rs-primary-border-radius;

  .cell-group-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;

    .cell-group-header-left {
      display: flex;
      align-items: cener;
      justify-content: space-between;
      gap: 1.5rem;
    }
    .cell-group-header-right {
      display: flex;
      align-items: cener;
      justify-content: space-between;
      gap: 1.5rem;
    }
  }
}
