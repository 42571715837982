@import '../../../../../styles/vars-and-mixins/index.less';

.cell-row {
  padding-bottom: 1rem;
}

.project-cell {
  .ant-card-body {
    min-height: 140px;
    padding: 12px 24px !important;

    .ant-row {
      margin-bottom: 0 !important;
    }
  }
}

.cell-item-section {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .ant-form-item {
    margin: 0;
  }

  .cell-item-col-title {
    font-weight: bold;
  }

  &.align-end {
    justify-content: flex-end;
    align-items: flex-end;
  }

  &.align-center {
    justify-content: flex-end;
    align-items: center;
  }

  &.row {
    flex-direction: row;
  }

  .height-40px {
    height: 40px;
  }

  .align-center {
    display: flex;
    align-items: center;
  }

  .justify-center {
    justify-content: center;
  }
}

.message-container {
  max-height: 90px;
  overflow-y: auto;
  padding: 8px 16px;
  border-radius: @rs-primary-border-radius;
  border: @rs-primary-border;
  margin-top: 5px;

  p {
    margin: 0 !important;
  }
}

.preview-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  width: 100%;
}

.preview-image-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;

  p {
    margin: 0;
    margin-bottom: 5px;
  }
}
