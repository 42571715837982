.settings-side-panel-section {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  background: white;
  z-index: 1;
}

.settings-wrapper {
  padding: 12px 24px;

  .setting-item-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .setting-item-name {
      font-weight: 600;
      margin-right: 6px;
    }
  }
}
