.multi-level-select {
  width: auto;

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 350px;
  }

  &__button-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__selected-item {
    font-weight: bold;
  }
}
