@import '../../../styles/vars-and-mixins/index.less';

.client-view-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.card {
  background: #ffffff;
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.head {
  padding: 1rem 0 0 0;
}

.body {
  padding: 0.5rem 0;
  border-bottom: solid 2px #f2f2f2;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.title {
  text-transform: capitalize;
}

.notes-container {
  min-height: 120px;
  max-height: 120px;
  overflow-y: auto;
  padding: 0.5rem;
  border-radius: @rs-primary-border-radius;
  border: @rs-primary-border;
  width: 100%;
}

.buttons-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;
  margin-top: 1rem;
}
