@import '../../../styles/vars-and-mixins/index.less';

.initial-search-state {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: @rs-secondary-text-color !important;
  height: 100%;
  margin-top: 50px;
}
