@import '../../../styles/vars-and-mixins/index.less';

.app-layout {
  width: 100%;
  min-height: 100vh !important;
  background-color: @rs-primary-bg-color;
  justify-content: center;
}

.site-content-wrapper {
  display: flex;
  flex: auto;
  flex-direction: column;
  background-color: @rs-primary-bg-color;
}

.site-content {
  margin-top: @layout-header-height;
  margin-left: @sidebar-expanded-width;
  transition: all @animation-duration-base;

  &.collapsed {
    margin-left: @sidebar-collapsed-width;
  }

  &.full-screen {
    margin-top: 0;
    margin-left: 0;
  }
}

.toggle-menu-icon {
  cursor: pointer;
  font-size: 18px;
}

.readyset-icon {
  height: 28px;
  margin-right: 1rem;
}
