.pdf-container {
  width: 100%;
  height: 100vh;
}

.video-tabs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1.5rem;
}

.video-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 50px;
}
