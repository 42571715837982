.drawer {
  .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }

  .ant-drawer-content {
    border-radius: 6px;
  }
}

.block-scroll {
  overflow: hidden !important;
}
