@import '../../../../../styles/vars-and-mixins/index.less';

.store-builder-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding: 0 24px;
  position: fixed;
  top: 68px; //ant-layout-header height.. make this a var later
  left: @sidebar-expanded-width;
  z-index: 1;
  background-color: @rs-primary-bg-color;
  width: 100%;

  &.collapsed-sidebar {
    left: @sidebar-collapsed-width;
  }
}
