@import '../color/index.less';
@import '../custom-theme/index.less';

///////////////////////////////////////////
// VARIABLES //
///////////////////////////////////////////

// Colors
@rs-primary-color: @violet-5;
@rs-primary-color-light: @violet-4;
@rs-primary-color-dark: @violet-6;
@rs-primary-dark-color: #000;
@rs-success-color: #52c41a;
@rs-success-color-light: #73d13d;
@rs-success-color-dark: #389e0d;
@rs-warning-color: #faad14;
@rs-warning-color-light: #ffc53d;
@rs-warning-color-dark: #d48806;
@rs-white-color: #fff;

@rs-primary-bg-color: #f2f2f2;
@rs-primary-text-color: @rs-primary-dark-color;
@rs-primary-icon-color: #becada;
@rs-primary-icon-active-color: @rs-primary-color;
@rs-icon-hover-bg-color: #f1f1f1;
@rs-secondary-text-color: #666;

@sidebar-bg-color: @rs-primary-color;
@header-bg-color: #ffffff;

// Styles
@rs-primary-border: 1px solid #dee2e6;
@rs-primary-border-radius: 6px;

@rs-btn-border-radius: @rs-primary-border-radius;

// @rs-primary-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
@rs-primary-hover-box-shadow: 0 12px 24px rgba(22, 25, 49, 0.07),
  0 4px 8px rgba(22, 25, 49, 0.05), 0 1px 0 rgba(22, 25, 49, 0.05);

@rs-primary-box-shadow: none;
// @rs-primary-hover-box-shadow: none;

@rs-item-border-radius: @rs-primary-border-radius;
@rs-item-box-shadow: @rs-primary-box-shadow;
@rs-item-hover-box-shadow: @rs-primary-hover-box-shadow;

@sidebar-collapsed-width: 80px;
@sidebar-expanded-width: 250px;

@rs-view-extra-header-margin-top: 20px;

///////////////////////////////////////////
// MIXINS //
///////////////////////////////////////////

.rs-icon {
  font-size: 18px;
  color: @rs-primary-icon-color !important;
}

.rs-icon-hover-effect {
  transform: translateY(0.5px);
  box-shadow: 0 3px 6px rgba(66, 90, 112, 0.25),
    0 1px 3px rgba(66, 90, 112, 0.25);
}

.link-button {
  color: @rs-primary-color;
  padding: 0;
  span {
    text-decoration: underline;
  }
}
