@import '../../../styles/vars-and-mixins/index.less';

.ovr-details-content-wrapper {
  width: 100%;

  .ant-form-item-label {
    text-align: left !important;
  }
}

.add-cells-action-text {
  color: @rs-primary-color;
  cursor: pointer;
  text-decoration: underline;
}

.cell-groups-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ovr-details-card {
  box-shadow: @rs-item-box-shadow;
  border: none;
  height: 100%;

  .ant-typography-copy {
    margin-left: 10px !important;
  }

  .respondant-details {
    display: flex;
    justify-content: center;
    width: 100%;

    .respondant-stats-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      gap: 1rem;

      .respondant-card {
        flex: 1;
        border-radius: @rs-primary-border-radius;
        height: 131px;
        width: 165px;
        border: @rs-primary-border;
        padding: 1rem;
        max-width: 300px;
        min-width: 150px;
      }

      .title-container {
        min-height: 40px;
      }

      .respondant-title {
        font-size: 1rem;
        font-weight: 700;
        color: @rs-primary-text-color;
        text-align: center;
      }

      // .text-container {
      // }

      .respondant-text {
        font-size: 2.5rem;
        font-weight: 700;
        color: #828282;
        text-align: center;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.dat-attention-tracking-button {
  &.ant-switch-checked {
    background-color: @rs-primary-color !important;
  }
}

.redirect-url-input-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}
