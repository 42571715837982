@import '../../../styles/vars-and-mixins/index.less';

.view-wrapper {
  box-sizing: border-box;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

  .view-wrapper-main {
    min-height: 100%;
    padding: 24px 0;
    width: 100%;

    &.max-width {
      max-width: 1600px;
    }
  }

  .view-wrapper__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    padding: 0 24px;
  }

  &.padded {
    padding: 0 24px;

    .view-wrapper__header {
      padding: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    &.padded {
      padding: 24px;

      .view-wrapper__header {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 24px;
        padding: 0;
      }
    }
  }

  &.loading {
    justify-content: center;
    align-items: center;
  }
}

.view-wrapper__header--section-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.view-wrapper__content {
  position: relative;
  flex-grow: 1;
}
