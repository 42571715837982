@import '../../../../../styles/vars-and-mixins/index.less';

.store-builder {
  height: 100%;
  width: 100%;
}

.builder-layout-editor {
  border-top: 1px solid #dee2e6;
  position: absolute;
  left: @sidebar-expanded-width;
  top: 120px;
  overflow: auto;
  height: calc(100vh - 52px - 68px);
  width: calc(100vw - 400px - @sidebar-expanded-width);

  &.collapsed-sidebar {
    left: @sidebar-collapsed-width;
    width: calc(100vw - 400px - @sidebar-collapsed-width);
  }
}

.builder-canvas {
  background-color: white;
  width: 100%;
  height: 100%;

  &.queue-line-mode {
    cursor: url('../../../../../assets//svgs/shopper-icon.svg') 10 16, pointer !important;
  }
}

.env-image {
  position: fixed;
  height: 1141px;
  width: 100%;
}

.exit-build-editor-button {
  position: fixed;
  height: 28px;
  width: 9px;
  z-index: 9;
  top: 80px;
  left: 274px;
  cursor: pointer;

  &.sidebar-collapsed {
    left: 104px;
  }
}

.opacity {
  opacity: 0.5 !important;
}

rect {
  &.label-opacity {
    opacity: 0 !important;
  }
}

rect {
  &.draw2d_shape_basic_Label {
    stroke-width: 0 !important;
  }

  &.draw2d_ResizeHandle {
    display: none;
  }

  &.draw2d_shape_basic_Rectangle {
    stroke-width: 0;
    fill: rgba(142, 36, 170, 0.7);
    fill-opacity: 1;
  }
}

tspan::selection {
  background: transparent !important;
  text-shadow: none !important;
}

text::selection {
  background: transparent !important;
  text-shadow: none !important;
}

.inplaceeditor {
  border: 1px dashed rgb(44, 112, 255) !important;

  &:focus {
    outline: none;
  }
}
