@import '../../../styles/vars-and-mixins/index.less';

.form-wrapper {
  .form-wrapper__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .form-wrapper__title {
    color: @rs-primary-dark-color;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: -0.35px;
    text-transform: capitalize;
  }

  .form-actions__close-icon {
    svg {
      height: 18px;
      width: 18px;

      g {
        stroke: @rs-primary-dark-color;
      }
    }
  }
}
