@import '../../../../styles/vars-and-mixins/index.less';

.current-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: @header-bg-color;
  width: 100%;
  padding: 4rem 2rem;
  border-radius: @rs-item-border-radius;
  min-height: calc(100vh - 4rem);

  .loading-spinner {
    font-size: 18px;
  }
}
