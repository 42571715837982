@import '../../../../../styles/vars-and-mixins/index.less';

.store-build-grid-item {
  border-radius: @rs-item-border-radius;
  box-shadow: @rs-item-box-shadow;
  background-color: #ffffff;
  cursor: pointer;
  color: @rs-primary-text-color;
  transition: box-shadow 0.25s ease-out, transform 0.25s ease-out,
    opacity 0.4s ease-out;

  &:hover {
    transform: translateY(-2px);
    transition-duration: 0.1s;
  }
}

.build-grid-item-section-top {
  height: 204px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.build-grid-item-bottom-section-left {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.build-grid-item-thumbnail {
  border: @rs-primary-border;
  display: block;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  max-width: 300px;
  height: auto;
  aspect-ratio: 16 / 9;

  &.loading {
    border: none;
    border-top-left-radius: @rs-item-border-radius;
    border-top-right-radius: @rs-item-border-radius;
  }

  &.no-image {
    color: #00000073;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.open-space {
    height: 62px;
  }

  &.open-space-w-customer-service {
    height: 84px;
  }

  &.full-size-grocery-space {
    height: 60px;
  }

  &.walmart-space {
    height: 60px;
  }

  &.c-store-space {
    height: 100%;
    width: 100px;
  }

  &.c-store-l-counter-space {
    height: 100%;
    width: 126px;
  }
}

.build-grid-item-section-bottom {
  padding: 16px 8px 14px 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: @rs-primary-border;

  .ant-skeleton-paragraph {
    margin-top: 12px !important;
  }
}

.build-item-title {
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
}

.build-item-subtitle {
  font-size: 14px;
  font-weight: normal;
}

.more-menu-icon {
  font-size: 20px;
}

.store-build-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: @rs-item-border-radius;
  box-shadow: @rs-item-box-shadow;
  background-color: #ffffff;
  cursor: pointer;
  padding-right: 16px;
  color: @rs-primary-text-color;

  &:hover {
    box-shadow: @rs-item-hover-box-shadow;
  }
}

.build-list-item-section-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.build-list-item-thumbnail {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 60px;
  width: 60px;
  border: none;
  display: block;
  position: relative;
  border-top-left-radius: @rs-item-border-radius;
  border-bottom-left-radius: @rs-item-border-radius;
}
