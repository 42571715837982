.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px 0;
  border-radius: 8px;

  .search-bar-left {
    flex-grow: 1;
    margin-right: 16px;

    .search-input {
      width: 100%;
      max-width: 330px;
    }
  }

  .ant-btn-primary {
    min-width: 40px;
  }
}

@media (max-width: 768px) {
  .search-bar {
    flex-direction: column;
    align-items: stretch;

    .search-bar-left {
      margin-right: 0;
      margin-bottom: 16px;

      .search-input {
        max-width: none;
      }
    }

    .ant-btn-primary {
      width: 100%;
    }
  }
}
