@import '../../../../../styles/vars-and-mixins/index.less';

.loading-more-spin-wrapper {
  width: 100%;
  text-align: center;
  margin-top: 24px;
}

.roles-table-list {
  box-shadow: @rs-item-box-shadow;
  border-radius: @rs-item-border-radius;

  .ant-table-thead > tr > th {
    background-color: #f2f2f2;
    font-weight: 500;
    font-size: 14.4px;
    color: #4f4f4f;

    .column-size {
      &:nth-child(2) {
        width: 400px;
      }
    }
  }

  .ant-btn-link {
    color: @violet-base;
    font-size: 14px;
    height: fit-content;
    border-bottom: 1px solid @violet-base;
    border-radius: 0;
    padding: 0;
  }
}

.role-table-list-action-icon {
  cursor: pointer;
  color: @rs-primary-icon-color;
  font-size: 18px;
}
