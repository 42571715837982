@import '../../../styles/vars-and-mixins/index.less';

.main-wrapper {
  width: calc(100% - 2rem);
  margin: 1rem;
  padding: 1rem;
  background-color: @header-bg-color;
  border-radius: @rs-primary-border-radius;

  .ant-space {
    width: 100%;
  }

  .actions-wrapper {
    display: flex;

    .ant-input-search-with-button {
      width: 412px;
    }

    .create-btn {
      margin-left: auto;
    }
  }

  .content-wrapper {
    margin-top: 0.875rem;
  }
}
