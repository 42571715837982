@import '../../../../../../../styles/vars-and-mixins/index.less';

.fixture-property-name {
  font-weight: 600;
  width: 128px;
}

.fixture-display-image {
  max-width: 320px;
  max-height: 240px;

  &.lg {
    max-width: 300px;
  }
}

.fixture-property-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixture-properties {
  padding: 12px 24px;
  height: 100%;
  width: 100%;

  &.shoppers {
    padding: 0;
  }
}

.fixture-property-value {
  display: flex;
  flex-direction: row;
  align-items: center;
}
