// .option-item {
//     .ant-card-body {
//       padding: 12px 24px !important;

//       .ant-row {
//         margin-bottom: 0 !important;
//       }
//     }
//   }

//   .option-item-section {
//     display: flex;
//     flex-direction: column;

//     .option-item-col-title {
//         font-weight: bold;
//     }

//     &.align-end {
//       justify-content: flex-end;
//       align-items: flex-end;
//     }

//     &.align-center {
//       justify-content: flex-end;
//       align-items: center;
//     }

//     &.row {
//       flex-direction: row;
//     }

//     .height-40px {
//       height: 40px;
//     }

//     .align-center {
//         display: flex;
//         align-items: center;
//     }

//     .justify-center {
//         justify-content: center;
//     }
//   }

.option-item {
  .ant-row {
    margin-bottom: 0 !important;
  }
}

.option-item-col-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.option-actions-section {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.editing {
    height: 48px;
  }
}
