.multi-level-select-dropdown-menu {
  max-height: 65vh;
  width: 100%;
  display: block;
  position: relative;
  overflow-y: scroll;
}

.multi-level-select-dropdown-back-button {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background-color: #f2f2f2;
  padding: 0.875rem 1.25rem;
  font-weight: bold;
  cursor: pointer;
}

.multi-level-select-dropdown-select-all-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #d9d9d9;

  .multi-level-select-dropdown-select-all-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px !important;
    font-size: smaller !important;
  }
}

.multi-level-select-dropdown-menu-items-container {
  max-height: 50vh;
  overflow-y: scroll;
}


