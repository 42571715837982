@import '../../../../../styles/vars-and-mixins/index.less';

.loading-more-spin-wrapper {
  width: 100%;
  text-align: center;
  margin-top: 24px;
}

.ovr-projects-table-list {
  box-shadow: @rs-item-box-shadow;
  border-radius: @rs-item-border-radius;

  .ant-table-thead > tr > th {
    background-color: white;
  }
}

.loading-skeleton-cards-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.ovr-project-table-list-action-icon {
  cursor: pointer;
  color: @rs-primary-icon-color;
  font-size: 18px;
}

@media only screen and (max-width: 1600px) {
  .loading-skeleton-cards-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 1200px) {
  .loading-skeleton-cards-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .loading-skeleton-cards-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 576px) {
  .loading-skeleton-cards-wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }
}
