@import '../../../../../styles/vars-and-mixins/index.less';

.loading-more-spin-wrapper {
  width: 100%;
  text-align: center;
  margin-top: 24px;
}

.custom-fields-table-list {
  box-shadow: @rs-item-box-shadow;
  border-radius: @rs-item-border-radius;

  .ant-table-thead > tr > th {
    background-color: white;
  }
}

.custom-field-table-list-action-icon {
  cursor: pointer;
  color: @rs-primary-icon-color;
  font-size: 18px;
}

.lazy-load-placeholder {
  height: 80px;
}