@import '../../../../../styles/vars-and-mixins/index.less';

.username-text {
  color: white;
  margin-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
}

.user-profile-menu {
  min-width: 222px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  background-color: @rs-primary-color-dark;
  border-radius: @rs-primary-border-radius;
  width: 222px;
  top: -10px;

  .user-profile-menu-item {
    height: 46px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: white;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #8e24aa;
    }
  }
}

.user-profile-menu__username {
  font-weight: bold;
  color: white;
  text-align: center;
}

.profile-menu-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 188px;
  text-align: center;
  cursor: default;
  pointer-events: none;
  padding: 24px;
}

.user-profile-dropdown {
  background-color: @rs-primary-color-dark;
  border-radius: @rs-primary-border-radius;
  padding: 12px 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &.collapsed {
    border-radius: 50%;
    width: 52px;
    height: 52px;
    align-items: center;
    justify-content: center;
  }
}

.user-profile-dropdown-menu-overlay.collapsed {
  padding-left: 14px;
}
