@import '../../../../../styles/vars-and-mixins/index.less';

.project-cells-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;

  .add-cell-btn {
    width: 100%;
    margin-top: 3rem;
  }
}

.project-cells-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.process-upload-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: @rs-primary-border;
  border-radius: @rs-primary-border-radius;
  min-height: 140px;

  p {
    margin: 0 1rem;
  }
}
