.package-info {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;

  .title {
    margin-bottom: 0.8rem !important;
  }
  .row {
    display: flex;
    flex-direction: column;
    width: 100%;

    .row-item {
      margin-bottom: 1.2rem;
    }

    .row-label {
      color: #828282;
      margin: 0;
    }

    .row-data {
      color: #333333;
      font-weight: 500;
      overflow-wrap: break-word;
      margin-bottom: 0;
    }

    .category {
      margin: 0.5rem 0;
    }

    .product-tag-wrapper {
      display: flex;
      flex-wrap: wrap;

      .product-tag {
        padding: 0.5rem 1rem;
        margin: 0.5rem;
        border-radius: 4px;
      }
    }
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
