@import '../../../styles/vars-and-mixins/index.less';

.store-builder-details-content-wrapper {
  display: grid;
  grid-template-columns: auto 400px;
  height: 100%;
}

.spin-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
