@import '../../../styles//vars-and-mixins/index.less';

.tooltip {
  position: fixed;
  border-style: solid;
  border-width: 1px;
  z-index: 99;
  background: white;
  max-width: 330px;
  border-radius: 10px;
  visibility: hidden;
  color: @rs-primary-color;
}

.tooltip-text {
  z-index: 99;
  padding: 6px;
  padding-left: 10px;
  border-radius: 10px;
  background: white;
  font-size: 11px;
  font-weight: 500;
  border-color: @rs-primary-color;
}

.tooltip:before {
  z-index: -1;
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  right: -1px;
  bottom: -32px;
  border-top: 46px solid @rs-primary-color;
  border-left: 46px solid #0000;
}

.tooltip:after {
  z-index: -1;
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  right: 0;
  bottom: -30px;
  border-top: 45px solid white;
  border-left: 45px solid #0000;
}
