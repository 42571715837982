@import '../../../../../styles/vars-and-mixins/index.less';

@select-client-dropdown-width: 222px;

.client-name-text {
  color: white;
}

.client-name-label {
  color: white;
  font-size: 10px;
  font-weight: bold;
}

.select-client-menu {
  min-width: @select-client-dropdown-width;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  background-color: @rs-primary-color-dark;
  border-radius: @rs-primary-border-radius;
  width: @select-client-dropdown-width;

  .select-client-menu-item {
    height: 46px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: white;
    padding-left: 16px;
    cursor: pointer;

    &:hover {
      background-color: #8e24aa;
    }
  }
}

.user-profile-menu__username {
  font-weight: bold;
  color: white;
  text-align: center;
}

.select-client-dropdown {
  background-color: @rs-primary-color-dark;
  border-radius: @rs-primary-border-radius;
  padding: 8px 16px;
  cursor: pointer;
  height: 46px;
  width: @select-client-dropdown-width;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &.collapsed {
    width: 52px;
    align-items: center;

    .client-name-text {
      color: white;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.select-client-dropdown-loader-wrapper {
  background-color: @rs-primary-color-dark;
  border-radius: @rs-primary-border-radius;
  height: 46px;
  width: @select-client-dropdown-width;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &.collapsed {
    width: 52px;
    align-items: center;
  }
}

.client-dropdown-menu-overlay {
  left: 14px !important;

  &.collapsed {
    left: 0px !important;
    padding-left: 14px;
  }
}
