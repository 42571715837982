@import '~antd/dist/antd.less';
@import './vars-and-mixins/index.less';

///////////////////////////////////////////
// FONTS //
///////////////////////////////////////////

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  src: url('../assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
    url('../assets/fonts/Roboto/Roboto-Regular.woff') format('woff'),
    url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: italic;
  src: url('../assets/fonts/Roboto/Roboto-Italic.woff2') format('woff2'),
    url('../assets/fonts/Roboto/Roboto-Italic.woff') format('woff'),
    url('../assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: lighter;
  src: url('../assets/fonts/Roboto/Roboto-Light.woff2') format('woff2'),
    url('../assets/fonts/Roboto/Roboto-Light.woff') format('woff'),
    url('../assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  src: url('../assets/fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
    url('../assets/fonts/Roboto/Roboto-Bold.woff') format('woff'),
    url('../assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

///////////////////////////////////////////
// GENERAL //
///////////////////////////////////////////

h1,
h3,
h4,
h5 {
  margin: 0 !important;
}

html,
body {
  margin: 0;
  color: @rs-primary-text-color;
  font-size: 14px;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: @rs-primary-bg-color;
}

.global-error-modal {
  & .ant-modal-confirm-btns {
    display: none;
  }

  & .ant-result.ant-result-error {
    padding: 0;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #757575;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

a {
  color: white;
}

.center-spin-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

///////////////////////////////////////////
// ANT //
///////////////////////////////////////////

.ant-spin-dot-item {
  background-color: @rs-primary-color;
}

.ant-checkbox-inner {
  border-radius: 3px;
}

.total-count-badge .ant-badge-count {
  background-color: @rs-primary-color;
  color: #ffffff;
  // box-shadow: @rs-item-box-shadow;
  border: none !important;
  min-width: 30px;
  min-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 12px;
}

.ant-switch-checked {
  background: @rs-primary-color;
}

// Tabs
.ant-tabs-tab {
  color: #6c757d;

  &:hover {
    color: #6c757d;
  }
}

.ant-tabs-tab-btn {
  color: @rs-primary-dark-color;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: @rs-primary-color;
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid @rs-primary-color;
}

@primary-color: @rs-primary-color;
