@import '../../../styles/vars-and-mixins/index.less';

.clients-view {
  &__search-bar-container {
    width: 100%;
  }

  &__content {
    display: flex;
    column-gap: 14px;
  }

  &__search-bar {
    display: flex;
    justify-content: space-between;
    border-radius: @rs-item-border-radius;
    margin-bottom: 14px;
    background-color: white;
    padding: 14px;
    column-gap: 15rem;
    row-gap: 14px;
    width: 100%;
    flex-wrap: wrap;

    &-left {
      display: flex;
      flex: 1;
    }

    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__search-input {
    min-width: 200px;
    width: 60%;

    .ant-input-affix-wrapper {
      &:not(.ant-input-affix-wrapper-disabled):hover,
      &:focus,
      &-focused {
        border-color: @rs-primary-color !important;
      }
    }

    ::selection {
      background: @rs-primary-color !important;
    }
  }

  &__table-wrapper {
    background: #ffffff;
    padding: 1.25rem;
    border-radius: 4px;
    min-height: 300px;
    width: 100%;
  }
}

.ant-input:focus {
  border-color: @rs-primary-color !important;
  outline: 0;
  box-shadow: 0 0 0 2px fade(@rs-primary-color, 20%);
}
