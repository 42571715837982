@import '../../../../styles/vars-and-mixins/index.less';

.link-button {
  color: @rs-primary-color;
  padding: 0;
  span {
    text-decoration: underline;
  }
}

.existing-categories {
  margin-bottom: 1rem;
  .category-title {
    font-size: 1.2rem;
    font-weight: 800;
    margin-bottom: 1rem;
  }
  .category-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    width: 60%;
    margin-bottom: 0.5rem;
    .category-item-text {
      margin: 0;
    }
  }
}

.category-list {
  display: flex;
  flex-direction: column;
  row-gap: 0;

  .ant-form-item {
    margin-bottom: 1rem;
  }

  .add-category-btn {
    width: 60%;
    margin-top: 2rem;
  }
}

.category-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 0;

  .category-select {
    width: 60%;
    margin-bottom: 0;
    .ant-btn {
      width: 100%;
    }
  }

  .remove-category-btn {
    margin-bottom: 0;
    margin-left: 1rem;
  }
}

.form-item-tags {
  margin-top: 1rem;
}

.hidden {
  display: none;
}

.approve-button {
  width: 100%;
  background: @rs-success-color;
  color: @header-bg-color;
  border-color: @rs-success-color;
  &:hover,
  &:focus,
  &:active,
  &:visited {
    background: @rs-success-color-light !important;
    border-color: @rs-success-color !important;
  }
}

.form-buttons-wrapper {
  .ant-btn-primary:active {
    background: @rs-success-color-light !important;
    border-color: @rs-success-color !important;
  }
}

.reject-button {
  width: 100%;
}

.checkbox-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  .ant-checkbox-wrapper {
    margin-bottom: 0;
  }
}

.editable-info-form-loading-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.existing-drc {
  margin-bottom: 2rem;
  padding-left: 2rem;
  .drc-title {
    font-size: 1.2rem;
    font-weight: 800;
    margin-bottom: 1rem;
  }
  .drc-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    width: 75%;
    margin-bottom: 0.5rem;
    .drc-item-text {
      margin: 0;
    }
  }
}

.drc-search-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.5rem;
  margin-bottom: 0.5rem;
  .drc-search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 1rem;
    width: 100%;
    .drc-search-input {
      width: 60%;
    }
    .drc-icons-container {
      display: flex;
      align-items: center;
      column-gap: 1.5rem;
      .drc-icon {
        font-size: 1.4rem;
      }
    }
  }
  .drc-result {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
