@import '../../../../../styles/vars-and-mixins/index.less';

.loading-more-spin-wrapper {
  width: 100%;
  text-align: center;
  margin-top: 24px;
}

.users-table-list {
  box-shadow: @rs-item-box-shadow;
  border-radius: @rs-item-border-radius;

  .ant-table-thead > tr > th {
    background-color: white;
  }

  .ant-tag {
    background-color: #f1e8f4;
    border: 1px solid @violet-base;
    border-radius: 4px;
    color: @violet-base;
  }

  .ant-btn-link {
    color: @violet-base;
    font-size: 14px;
    height: fit-content;
    border-bottom: 1px solid @violet-base;
    border-radius: 0;
    padding: 0;
  }
}

.user-table-list-action-icon {
  cursor: pointer;
  color: @rs-primary-icon-color;
  font-size: 18px;
}
