.log-in-view {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #8e24aa;
}

.log-in-form-button {
  width: 100%;
  margin-top: 29px;
}

.readyset-logo {
  margin-bottom: 2.8rem;
}

.log-in-card-content-wrapper {
  padding: 18px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .log-in-password-input {
    margin-bottom: 16px;
  }

  .log-in-forgotten-label {
    text-decoration: underline;
    color: #8e24aa;
  }

  .log-in-step-header {
    font-weight: 700;
  }

  .mfa-choice-buttons {
    display: flex;
    gap: 12px;
    width: 100%;
    margin-top: 24px;

    button {
      flex: 1;
    }
  }
}
