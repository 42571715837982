@import '../../../../../../../../../styles/vars-and-mixins/index.less';

.twitter-picker {
  top: 80px;
  left: 126px;

  & span div {
    border: @rs-primary-border;
  }
}
