@import '../../../../../styles/vars-and-mixins/index.less';

.ovr-grid-item {
  border-radius: @rs-item-border-radius;
  box-shadow: @rs-item-box-shadow;
  background-color: #ffffff;
  cursor: pointer;
  color: @rs-primary-text-color;
  transition: box-shadow 0.25s ease-out, transform 0.25s ease-out,
    opacity 0.4s ease-out;

  &:hover {
    // box-shadow: @rs-item-hover-box-shadow;
    transform: translateY(-2px);
    transition-duration: 0.1s;
  }

  .ant-skeleton-title {
    margin-bottom: 12px !important;
  }

  .ant-skeleton-paragraph {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    & li:first-child {
      margin-top: 0 !important;
    }

    & li {
      margin-top: 12px !important;
    }
  }
}

.ovr-grid-item-detail-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ovr-grid-item-thumbnail {
  background-repeat: no-repeat;
  border: none;
  display: block;
  position: relative;
  height: 158px;
  border-top-left-radius: @rs-item-border-radius;
  border-top-right-radius: @rs-item-border-radius;
}

.ovr-grid-item-section-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 8px 12px 16px;
  overflow: hidden;
}

.ovr-grid-item-top-section-left {
  overflow: hidden;
}

.ovr-grid-item-section-bottom {
  padding: 16px 8px 14px 16px;
  position: relative;
  display: grid;
  grid-template-rows: auto auto auto;
  border-top: @rs-primary-border;
}

.ovr-item-title {
  margin-bottom: 0.2rem;
  white-space: nowrap;
  overflow: hidden;
}

.ovr-item-subtitle {
  margin-bottom: 0.2rem;
  white-space: nowrap;
  overflow: hidden;
}

.more-menu-icon {
  .rs-icon();
  padding: 8px;
  border-radius: 50%;

  &:hover {
    background-color: @rs-icon-hover-bg-color;
  }

  &.active {
    background-color: @rs-icon-hover-bg-color;
  }
}
