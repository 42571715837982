.notes {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 2rem;

  .title {
    margin-bottom: 0.8rem !important;
  }
}
