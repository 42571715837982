@import '../../../../styles/vars-and-mixins/index.less';

.link-button {
  color: @rs-primary-color;
  padding: 0;
  span {
    text-decoration: underline;
  }
}

.categories-select {
  margin-bottom: 0;
  button,
  html [type='button'],
  [type='reset'],
  [type='submit'] {
    min-width: 250px;
    width: auto;
  }
}

.form-item-select {
  .ant-select-selector,
  .ant-input {
    min-width: 200px;
  }
}

.form-item-tags {
  margin-top: 1rem;
}
.form-item-categories-wrapper {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  .form-item-categories {
    width: 60%;

    .dropdown-button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .dynamic-delete-button {
    margin-bottom: 24px;
  }
}

.approve-button {
  width: 100%;
  background: @rs-success-color;
  color: @header-bg-color;
  border-color: @rs-success-color;
  &:hover,
  :focus,
  :active,
  :visited {
    background: @rs-success-color-light !important;
    border-color: @rs-success-color !important;
  }
}

.form-buttons-wrapper {
  .ant-btn-primary:active {
    background: @rs-success-color-light !important;
    border-color: @rs-success-color !important;
  }
}

.reject-button {
  width: 100%;
}
