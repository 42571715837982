.unity-player-wrapper {
  position: relative;
  margin-top: -68px;
  height: 100vh;
  background: #160826;
  overflow: hidden;
  pointer-events: all;

  .unity-player {
    border: none;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.drop-overlay {
  position: absolute;
  min-height: 100vh;
  width: 100%;
  left: 0;
  right: 35px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(150, 0, 195, 0.333);
  color: white;
  font-size: 24px;
  font-weight: bold;
  z-index: 999;
}
