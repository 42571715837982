@import '../../../styles/vars-and-mixins/index.less';

.products-panel-drawer {
  position: fixed !important;
  top: 45px !important;
  height: 100vh !important;
  right: 35px !important;

  .ant-drawer-mask {
    display: none !important;
  }

  .ant-drawer-content-wrapper {
    right: 5px !important;
    transition: none !important;

    &.ant-drawer-right > .ant-drawer-content-wrapper {
      right: 45px;
    }
  }

  // Drawer Header
  .ant-drawer-wrapper-body {
    background-color: #f5f5f5 !important;

    .ant-drawer-header {
      padding-bottom: 10px;

      .ant-drawer-header-title {
        display: flex;
        flex-direction: row-reverse;

        .ant-drawer-title {
          font-weight: normal;
          font-family: sans-serif;
          color: rgba(0, 0, 0, 0.52);
        }

        .ant-drawer-close {
          margin-right: 0;
          padding: 0;

          svg {
            fill: rgba(0, 0, 0, 0.52);
          }
        }
      }
    }

    .ant-drawer-body {
      padding: 15px;
      padding-top: 0;
    }
  }

  // Main Content Panel
  .products-panel-content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: @rs-primary-border-radius;
    flex: 1;
    min-height: 0;
    overflow: hidden;
    background-color: white !important;

    // Filter Controls Section
    .filter-controls {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 1rem;

      .filter-controls-row {
        display: flex;
        gap: 10px;

        .filter-button {
          width: auto;
          padding: 0 2rem;
        }
      }
    }

    // Form Controls
    .product-category-select,
    .product-search-input,
    .product-sort-select,
    .filter-button {
      width: 100%;
    }

    .product-search-input {
      margin-bottom: 10px;
    }

    // Products Grid
    .products-grid {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      overflow-y: auto;
      flex: 1;
      min-height: 0;
      gap: 1rem;
      margin-top: 1rem;
    }
  }

  // Selected Filters Section
  .selected-filters {
    padding: 12px 0;
    border-bottom: 1px solid #f0f0f0;

    .filter-category-title {
      font-weight: 500;
      margin-bottom: 8px;
    }

    .selected-filter-item {
      display: inline-block;
      background: #f5f5f5;
      padding: 4px 8px;
      border-radius: 4px;
      margin: 0 4px 4px 0;
      font-size: 12px;
    }
  }

  // Pagination
  .pagination-container {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    padding: 16px 0;
  }
}

// Global Ant Design Overrides
.ant-collapse-ghost {
  .ant-collapse-header {
    padding: 12px 0 !important;
    font-weight: 500;
  }
}

.ant-checkbox-group {
  padding-left: 8px;
}

.ant-cascader-dropdown {
  max-height: 300px;
  overflow-y: auto;
}
