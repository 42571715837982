.fixture-item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.fixture-item-image {
  width: auto;

  &.sm {
    height: 56px;
  }

  &.md {
    height: 72px;
  }

  &.lg {
    height: 100px;
  }
}

.fixture-item-label {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #6b757d;
  font-weight: bold;
}
