@import '../../../../../styles/vars-and-mixins/index.less';

.product-library-grid-item {
  border-radius: @rs-item-border-radius;
  background-color: #ffffff;
  color: @rs-primary-text-color;
  border: @rs-primary-border;
  padding: 1rem;
}

.product-library-grid-item-thumbnail {
  object-fit: contain;
  height: 177px;
  margin-bottom: 1rem;
}

.product-library-grid-item-section-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.product-library-grid-item-section-bottom {
  margin-top: 1rem;
  width: 100%;
}

.product-library-item-upc {
  margin-bottom: 0.2rem;
  overflow-wrap: break-word;
}

.product-library-item-title {
  margin-bottom: 0.5rem;
  overflow-wrap: break-word;
}

.product-library-item-size {
  margin-bottom: 0.2rem;
  overflow-wrap: break-word;
}
.product-library-item-count {
  margin-bottom: 0.2rem;
  overflow-wrap: break-word;
}
