.status {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  border-radius: 4px;
  padding: 1rem;

  .title {
    margin-bottom: 0.8rem !important;
  }

  .row-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    column-gap: 1rem;

    .row {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      gap: 1rem;
      flex: 1;

      .row-item {
        flex: 1;

        .row-label {
          color: #828282;
          font-weight: 500;
        }
        .row-item-data {
          color: #333333;
        }
      }
    }
    .quality-label {
      color: #828282;
      margin: 0;
      padding: 0;
    }

    .quality-value {
      margin: 0;
      padding: 0;
      border-bottom: 2px solid #f2f2f2;
    }
  }
}

.model-info {
  display: flex;
  margin-bottom: 1rem;

  .row {
    margin-bottom: 0.5rem;
  }
}

hr {
  border: 1px solid #f2f2f2;
  margin: 0;
}
