.build-side-image-caption {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #f1556c;
}

.build-side-image {
  width: 100%;
}
