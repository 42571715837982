@import '../../../styles/vars-and-mixins/index.less';

.master-librarian-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 4px;
  min-height: 300px;

  &__header {
    padding: 14px;
    padding-bottom: 0;
    background-color: white;
    border-radius: @rs-item-border-radius @rs-item-border-radius 0 0;
  }

  &__search-bar-container {
    display: flex;
    width: 100%;
  }

  &__content {
    display: flex;
    column-gap: 14px;
    margin-top: 1rem;
  }

  &__search-bar {
    display: flex;
    justify-content: space-between;
    border-radius: @rs-item-border-radius;
    background-color: white;
    padding: 16px;
    width: 100%;
    flex-wrap: wrap;

    &-left {
      display: flex;
      max-width: 60%;
      flex: 1;
      margin-right: 1rem;
    }

    &-right {
      display: flex;
      align-items: center;
    }
  }

  &__search-input {
    min-width: 200px;
    width: 100%;
  }
}

.center-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
