@import '../../../styles/vars-and-mixins/index.less';

.download-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  padding: 0.5rem 1rem 1rem 1rem;

  &:hover {
    background: #f0f0f0;
  }

  &:last-child {
    border-bottom: none;
  }

  .download-item-content {
    flex-grow: 1;
  }

  .download-item-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 8px;

    .status-text {
      font-size: 14px;
      font-weight: 500;
      color: #262626;
    }

    .date-text {
      font-size: 10px;
      color: #8c8c8c;
    }
  }

  .download-item-body {
    flex-grow: 1;
    margin-right: 0.5rem;
  }

  .download-item-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .delete-text {
      font-size: 14px;
      color: #8c8c8c;
      cursor: pointer;

      &:hover {
        color: #595959;
      }
    }

    .progress-text {
      font-size: 14px;
      color: #262626;
    }
  }
}

.alert-font-size {
  font-size: 14px;
}

.download-button {
  margin-top: 0.5rem;
}
