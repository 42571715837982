@import '../../../styles/vars-and-mixins/index.less';

.draggable-product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 130px;
  min-width: 130px;
  border: 1px solid #ddd;
  border-radius: 0;
  background-color: @rs-white-color;
  cursor: move;

  &.dragging {
    opacity: 0.5;
  }

  .product-image {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @rs-white-color;
    padding: 8px;

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }

  .product-info {
    width: 100%;
    padding: 0.5rem;
    background-color: @rs-primary-bg-color;
    text-align: left;
    flex: 1;

    .product-name {
      font-weight: bold;
      word-break: break-word;
    }

    p {
      margin: 0 !important;
      padding: 0 !important;
      font-size: 12px;
      line-height: 1.4;
    }
  }
}

.product-tooltip {
  p {
    margin: 2px 0;
    font-size: 14px;
    line-height: 1.2;
    color: #ffffff;

    strong {
      color: #ca88fc;
      margin-right: 4px;
    }
  }
}

.ant-tooltip-inner {
  background-color: rgba(0, 0, 0, 0.85) !important;
}
