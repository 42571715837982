@import '../../../../styles/vars-and-mixins/index.less';

.products-table-list-wrapper {
  height: 100%;
  display: 'flex';
  flex-direction: 'column';
  gap: 14px;
  background: @header-bg-color;
  padding: 2rem;
  border-radius: @rs-item-border-radius;
  width: 100%;
}

.products-table-list {
  background: @header-bg-color;
  box-shadow: @rs-item-box-shadow;
  border-radius: @rs-item-border-radius;

  .ant-table-thead > tr > th {
    background-color: @header-bg-color;
  }
}

.products-table-header {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
}

.total-count-badge {
  margin-left: 1rem;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: @rs-primary-icon-active-color;
}

.table-footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .table-footer-left {
    span {
      margin: 0 1rem;
      font-weight: bold;
    }
  }
}

.link-button {
  color: @rs-primary-color;
}

.ant-pagination-item-active {
  border-color: @rs-primary-color;
}

.ant-pagination-item-active a {
  color: @rs-primary-color;
}

.ant-pagination-item a {
  &:hover {
    color: @rs-primary-color;
  }
}

.ant-pagination-item:hover {
  border-color: @rs-primary-color;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: @rs-primary-color;
  border-color: @rs-primary-color;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: @rs-primary-color;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: @rs-primary-color;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: rgba(@rs-primary-color, 0.1);
}

.ant-table-cell {
  background: @header-bg-color;
}

.ant-table-tbody > tr > td {
  background: @header-bg-color;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @rs-primary-color;
  border-color: @rs-primary-color;
}

.checkbox-red .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: @rs-primary-color;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background: @rs-primary-color;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: rgba(@rs-primary-color, 0.1);
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: rgba(@rs-primary-color, 0.1);
}
