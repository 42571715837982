.upload-model-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  padding: 1.25rem;
  border-radius: 4px;
  min-height: 300px;

  .sub-text-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    margin-top: 0.5rem;
  }

  .upload-btn {
    margin-top: 2rem;
  }

  .ant-upload.ant-upload-btn {
    padding: 43px 0;
  }
}

.dragger-input-text {
  h4 {
    margin-bottom: 7px !important;
  }
  .description {
    font-size: 14px;
    color: #9ba4aa;
  }
}

.progress-bar-wrapper {
  margin-top: 1rem;

  .ant-progress-text span {
    color: #8e24aa;
  }
}

.table-header-wrapper {
  display: flex;
  justify-content: space-between;
}

.products-table-wrapper {
  margin-top: 1rem;
  .ant-badge-count {
    background-color: #828282;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-badge-count {
    background-color: #8e25aa;
  }
}

.ant-checkbox-wrapper-disabled {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #f5f5f5 !important;
      border-color: #d9d9d9 !important;

      &::after {
        border-color: rgba(0, 0, 0, 0.25) !important;
      }
    }
  }
}
