.row {
  display: flex;
  flex-direction: column;
  width: 100%;

  .row-item {
    margin-bottom: 1.2rem;
  }

  .row-label {
    color: #828282;
    margin: 0;
  }

  .row-data {
    color: #333333;
    font-weight: 500;
    overflow-wrap: break-word;
    margin-bottom: 0;
  }
}

hr {
  border: 1px solid #f2f2f2;
  margin: 0;
}
