@import '../../../styles/vars-and-mixins/index.less';

.store-builder-view-type-label {
  margin-right: 1rem;
}

.store-builder-view-type-icon {
  cursor: pointer;

  &:hover {
    .rs-icon-hover-effect();
  }

  g,
  path {
    fill: @rs-primary-icon-color;
  }

  &.selected {
    g,
    path {
      fill: @rs-primary-dark-color;
    }
  }
}
