@import '../../../../../styles/vars-and-mixins/index.less';

.image-selection {
  .current-image-wrapper {
    display: relative;

    .current-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 500px;
      height: calc(1em + 15vw);
      margin-bottom: 21px;
      background: #ffffff;
      border-radius: 4px;

      @media only screen and (max-width: 1024px) {
        min-height: 350px;
      }
    }

    .magnifying-glass {
      position: absolute;
      right: 30px;
      top: 455px;

      @media only screen and (max-width: 1024px) {
        right: 15px;
        top: 315px;
      }
    }
  }

  .thumbnail-selection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 2vw;
    row-gap: 1vw;
    width: 100%;
    margin-bottom: 21px;
    flex-wrap: wrap;

    .three-d-thumbnail {
      display: flex;
      min-width: 6vw;
      min-height: 6vw;
      background: #ffffff;
      align-items: center;
      justify-content: center;
      object-fit: cover;
    }
    .three-d-thumbnail-active {
      display: flex;
      min-width: 6vw;
      min-height: 6vw;
      background: #ffffff;
      align-items: center;
      justify-content: center;
      object-fit: cover;
      border: 2px solid @rs-primary-color;
      padding: 10px;
    }
  }
}
