@import '../../../styles/vars-and-mixins/index.less';

.downloads-dropdown {
  background: @header-bg-color;
  border-radius: 8px;
  @media only screen and (min-width: 2560px) {
    left: 75.2vw !important;
  }
  @media only screen and (min-width: 2800px) {
    left: 73.2vw !important;
  }
}

.downloads-dropdown-menu {
  border-radius: 8px;
  min-width: 250px;
}

.downloads-dropdown-menu-item {
  padding: 0;
}

.loading-container {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.loading-text {
  margin: 0;
}
